import React from 'react'
import '../global.css'
// import { Container } from '@mui/material';

export function Herosection() {
    return (

        <div className="hero-section" id='home'>
            <div className="gradient-overlay"></div>
            <div className="hero-content">
                <h4>WELCOME TO AI DRIVEN</h4>
                <h1>Leading Ai<br />Marketing Agency<br />Based on Noida.</h1>
                <div className="button-container">
                    {/* <button id='contact'>Get Started</button> */}
                    {/* <button>Learn More</button> */}
                </div>
            </div>
        </div>


    )
}
